import React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import SlicesZone from "../containers/Sliceszone"
import { ContentWrapper } from "../containers/alpacked.style"

const CaseStudiesPostTemplate = ({ data, location }) => {
  const {
    seo_title,
    seo_short_description,
    seo_description,
    image_link_preview,
    body,
    title,
  } = data.prismicCaseStudiesPost.data

  const { first_publication_date } = data.prismicCaseStudiesPost

  return (
    <>
      <SEO
        image={image_link_preview.fluid?.src}
        location={location}
        title={seo_title.text}
        shortDesc={seo_short_description.text}
        description={seo_description.text}
      />
      <Layout pathname={location.pathname}>
        <ContentWrapper>
          <SlicesZone
            location={location}
            content={body}
            date={first_publication_date}
            breadCrumbsContent={{
              category: { title: "Case Studies", url: "case-studies" },
              page: {
                title: title.text,
              },
            }}
          />

          {/* {filteredRelatedBlogPost.length > 0 && (
            <RelatedArticles content={filteredRelatedBlogPost} />
          )} */}
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default withPreview(CaseStudiesPostTemplate)

export const BlogPostPage = graphql`
  query caseStudiesPostContent($slug: String!) {
    allPrismicCaseStudiesPost(
      filter: { uid: { ne: $slug } }
      limit: 3
      sort: { order: DESC, fields: last_publication_date }
    ) {
      edges {
        node {
          uid
          id
          tags
          first_publication_date(formatString: "MMM DD, YYYY")
          data {
            intro_text
            title {
              text
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 72) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicCaseStudiesPost(uid: { eq: $slug }) {
      first_publication_date(formatString: "MMM DD, YYYY")
      uid
      data {
        seo_title {
          text
        }
        seo_short_description {
          text
        }
        seo_description {
          text
        }
        image_link_preview {
          fluid {
            src
          }
        }
        title {
          text
        }

        body {
          ... on PrismicCaseStudiesPostBodyBannerBlog {
            id
            slice_type
            primary {
              subtitle
              title1 {
                html
              }
              image1 {
                url
              }
            }
          }
          ... on PrismicCaseStudiesPostBodyProjectDetails {
            id
            slice_type
            items {
              content {
                html
              }
              title1
              image_position
              image1 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 72) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicCaseStudiesPostBodyCaseStudies2ColumnText {
            id
            slice_type
            primary {
              column_a_text {
                html
              }
              column_b_text {
                html
              }
            }
          }
          ... on PrismicCaseStudiesPostBodyProjectInfo {
            id
            slice_type
            items {
              keypoint
              icon {
                url
              }
            }
            primary {
              project_title
              project_description
              team
              period
              location
              client_title
              benefits_title
              client_info {
                html
              }
            }
          }
          ... on PrismicCaseStudiesPostBodyChallenges {
            id
            slice_type
            primary {
              background_color
              description
              titlechallenges
            }
            items {
              align
              content {
                html
              }
            }
          }
          ... on PrismicCaseStudiesPostBodyFreetextcasestudies {
            id
            slice_type
            items {
              free_text_image_position
              free_text_title_width
              free_text_title {
                html
              }
              free_text_title_colored {
                html
              }
              free_text_free_content {
                html
              }
            }
          }
          ... on PrismicCaseStudiesPostBodyRelatedCaseStudiesPosts {
            id
            slice_type
            items {
              related_case_studies {
                document {
                  ... on PrismicCaseStudiesPost {
                    id
                    uid
                    tags
                    first_publication_date(formatString: "MMM DD, YYYY")
                    data {
                      intro_text
                      title {
                        text
                      }
                      image {
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 800, quality: 72) {
                              ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
